@import '../../../styles/variables.less';
@import '../../../styles/global/variables.less';

.my-jobs {
  .ant-card-type-inner .ant-card-head {
    padding: 24px 24px 0px 24px;
  }
  height: 100%;
  min-height: 100vh;
  .my-job-card {
    cursor: pointer;
  }
  background-color: @white-color;
  padding: 3% 4%;
  @media screen and (max-width: 1000px) {
    width: 100vw;
  }
  .ant-card-type-inner .ant-card-head {
    background: white;
  }
  .viewDetailWeb {
    @media screen and (max-width: 920px) {
      display: none;
    }
  }
  .btn-follow {
    padding: 0.1rem;
    width: auto;
    height: 2.2rem;
  }
  .viewDetailMobile {
    @media screen and (min-width: 920px) {
      display: none;
    }
    .btn-follow {
      padding: 0.25rem;
      width: 100%;
      height: 2.5rem;
    }
  }
  .buttonCenterMobile {
    @media screen and (max-width: 920px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-top: 1em;
    }
  }
  .noMobileFlex {
    @media screen and (max-width: 750px) {
      display: unset;
    }
  }
  .verticalLine {
    @media screen and (max-width: 750px) {
      display: none;
    }
  }
  .align-center-text {
    text-align: center;
  }
  .w-100 {
    width: 100%;
  }

  .shadow {
    border-color: transparent;
    box-shadow: 0 4px 12px rgb(0 0 0 / 9%);
  }
  .heading {
    color: #000;
    margin-bottom: 0%;
  }
  * {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
  }
  .margin-bottom {
    margin-bottom: 1rem;
  }
  .pl {
    padding-left: inherit;
  }
  .dark-btn {
    background-color: #000;
    border-color: #000;
    color: #fff;
    padding: 8px 10px;
  }
  .text-blue {
    color: #3b7ddd;
    font-weight: bold;
  }
  @media screen and (max-width: 750px) {
    .mt-mobile {
      margin-block: 0.5rem;
    }
  }
}
