@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.search-component {
  .ant-input {
    //color: @field-color;
    font-weight: 500;
    background-color: @field-background-color;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid @border-color;
    border-radius: unset;
  }
  .ant-input::placeholder {
    color: #8b8d95;
  }

  .ant-input:placeholder-shown {
    background-color: @field-background-color;
  }
  .anticon {
    &.anticon-close-circle {
      &.ant-input-clear-icon {
        margin-left: 2px;
        text-align: center;
      }
    }
  }
}

.search-component.ant-input-affix-wrapper {
  border: none;
  background-color: @field-background-color;
  border-radius: 6px;
  height: 32px;
  padding: 8px;
  transition: none;
}

.search-component.ant-input-affix-wrapper:focus {
  //border: 1px solid @border-color;
  padding: 9px;
  outline: 0;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid @border-color;
}
.search-component.ant-input-affix-wrapper-disabled:hover {
  border: none;
  padding: 9px;
}

.search-component.ant-input-affix-wrapper > input.ant-input {
  background-color: @field-background-color;
  box-shadow: none;
}

.search-component.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: none;
  border-color: @border-color;
  z-index: 1;
  background-color: @field-background-color;
}
