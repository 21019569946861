.company-profile {
  .space-very-small-h {
    width: 5px;
  }
  .space-small-h {
    width: 10px;
  }
  .space-small-v {
    height: 10px;
  }
  .space-very-small-v {
    height: 5px;
  }
  .space-large-v {
    height: 20px;
  }
  .company-review {
    padding: 5px;
    .review-card {
      background: #ffffff;
      box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
    }
    .review-card:hover {
      background: #ffffff;
      box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
    }
    h3 {
      margin: 0px;
    }
    .title-bio {
      font-size: 13px;
      margin: 0;
    }
    .no-review {
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .loadMoreButton {
      text-align: center;
      padding: 20px;
      width: 100%;
    }
  }

  .company-profile-upper-mobile {
    display: none;
    height: 80vh;
    position: relative;
    @media screen and (max-width: 750px) {
      display: block;
    }

    .cover-container {
      height: 35%;
      background-size: cover;
      background-position: center;
    }
    .company-detail-container {
      height: 65%;
      padding: 10px 25px;
    }
    .company-profile-mobile {
      position: absolute;
      top: 29%;
      left: 5%;
    }
  }
  .company-profile-upper-web {
    @media screen and (max-width: 750px) {
      display: none;
    }
    .job-icon {
      height: 18px;
    }
    .other-info {
      font-size: 15px;
    }
    .company-details {
      padding-left: 6%;
      padding-top: 4%;
      width: 55%;
      @media screen and (max-width: 1000px) {
        width: 65%;
      }
      .other-info {
        font-size: 15px;
      }
      h1 {
        font-size: 30px;
      }
      p {
        font-size: 15px;
      }
    }
    .cover-container {
      width: 45%;
      position: relative;
      height: 444px;
      background-size: cover;
      background-position: center;
      @media screen and (max-width: 1000px) {
        width: 45%;
      }

      button.ant-btn.change-cover-btn {
        position: absolute;
        top: 90%;
        left: 80%;
        transform: translate(-50%, -50%);
        background: #00000033;
        backdrop-filter: blur(50px);
        border-radius: 100px;
        color: white;
        font-size: 16px;
        padding: 12px 24px;
        border: none;
        cursor: pointer;
        text-align: center;
      }
    }
    .cover-container .btn:hover {
      background-color: black;
    }
  }

  .loadMoreButton {
    text-align: center;
    padding: 20px;
  }
  background: @white-color;
  height: 100%;
  .profile-image {
    border: 1px solid #dde2e5;
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .post-video {
    height: 75%;
    display: flex;
    padding-bottom: 15px;
    align-items: center;
    background-color: #000;
    .video {
      height: auto;
      width: 100%;
    }
  }
  .profile-post {
    padding: 10px;
    @media screen and (max-width: 750px) {
      padding: 0px;
    }
  }
  .follower-list {
    background-color: @white-color;
    padding: 20px;
    .ant-col {
      padding: 6px;
    }
    .follower-card {
      background: @white-color;
      box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      padding: 25px;
      display: flex;
      height: 225px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .follower-name {
        color: @black-color;
        font-weight: 500;
      }
      .follower-bio {
        color: #495057;
        font-size: 12px;
      }
      .actions {
        width: 40px;
        height: 30px;
        margin-top: 16px;
        border: 1px solid #eeeeee;
        background-color: @white-color;
        border-radius: 8px;
        color: black;
        text-align: center;
        &:hover {
          background: rgba(59, 125, 221, 0.1);
          border: 1px solid #3b7ddd;
          color: #3b7ddd;
        }
      }

      .action-popover {
        .ant-popover-placement-bottomRight {
          & > .ant-popover-content {
            background-color: antiquewhite;
            .ant-divider-horizontal {
              margin: 3px 0;
            }
            span {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .jobs-list {
    background-color: @white-color;
    @media screen and (min-width: 320px) and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .single-job {
      padding: 10px 20px;
      background-color: white;
    }
    .job-card {
      padding: 25px;
      cursor: pointer;
      height: 230px;
      background-color: @white-color;
      border-radius: 8px;
      box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.04);
      @media screen and (min-width: 320px) and (max-width: 991px) {
        width: 100%;
        padding: 15px;
      }
      .info-section {
        display: flex;
        flex-direction: row;
        .job-left-section {
          width: 60%;
          .big-text {
            font-weight: bold;
            color: @black-color;
          }
          .mt-15 {
            margin-top: 15px;
          }
          .required-info {
            margin-top: 15px;
            width: 210px;
            height: 40px;
            color: @white-color;
            background-color: @black-color;
            border-radius: 100px;
            padding: 8px 5px;
          }
          .location-info {
            font-size: 12px;
            margin-top: 10px;
            .location-label {
              margin-top: 2px;
              margin-left: 6px;
              color: #34a853;
            }
            .location-value {
              color: @black-color;
            }
          }
          @media screen and (min-width: 320px) and (max-width: 991px) {
            width: 100%;
          }
        }
        .job-right-section {
          width: 40%;
          display: flex;
          justify-content: flex-end;
          color: #3b7ddd;
          font-weight: bold;
        }
      }
      .qualification-section {
        .big-text {
          color: @black-color;
          font-weight: 500;
          font-size: 16px;
        }
        ul {
          margin-top: 12px;
          :not(:first-child) {
            margin-top: 5px;
          }
        }
        ul > li {
          color: #495057;
          font-size: 12px;
        }
      }
    }
  }
  .about-company {
    .big-text {
      color: @black-color;
      font-size: 18px;
    }
    .small-text {
      color: #495057;
      font-size: 13px;
    }
    .mt-10 {
      margin-top: 10px;
    }
    .ml-20 {
      margin-left: 20px;
    }
    .overview-funding-section {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .company-over-view {
        width: 60%;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 750px) {
          width: 100%;
        }
      }
      .company-funding {
        width: 40%;

        .funding-info {
          display: flex;
          align-items: center;
          margin-top: 15px;
        }
        .ant-divider-horizontal {
          margin: 10px 0;
          width: 265px;
        }
        .mt-12 {
          margin-top: 12px;
        }
        .round-info {
          font-size: 12px;
          .round-label {
            color: @black-color;
            font-weight: 500;
          }
        }
        .found-amount {
          margin-top: 12px;
          font-size: 16px;
          color: @black-color;
        }
      }
    }
    .showOnlyWeb {
      display: block;
      @media screen and (max-width: 750px) {
        display: none;
      }
    }
    .showOnlyMobile {
      display: none;
      @media screen and (max-width: 750px) {
        display: block;
      }
    }

    .website-section {
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      width: 10%;
      .website-block {
        padding: 10px;
        width: 120px;
        background: rgba(59, 125, 221, 0.1);
        border-radius: 100px;
        color: #3b7ddd;
      }
      img {
        margin-left: 12px;
        height: 10px;
        width: 10px;
      }
    }

    .other-info {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
    }
  }
}

.connection-section {
  background-color: #fff;
  position: relative;
  padding: 10px 10px;
  .ant-tabs-nav {
    position: fixed !important;
    z-index: 100;
    width: 100%;
    background: white;
  }

  @media screen and (max-width: 750px) {
    .company {
      padding-right: 0px;
    }
    .img {
      height: 70px;
      width: 70px;
    }
  }
  @media screen and (min-width: 750px) {
    .img {
      height: 85px;
      width: 85px;
    }
  }
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      &:first-child {
        width: 200px;
        cursor: unset;
        color: #000;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  .viewDetailWeb {
    @media screen and (max-width: 750px) {
      display: none;
    }
    .company,
    .job {
      width: 80vw;
    }
  }
  .viewDetailMobile {
    @media screen and (min-width: 749px) {
      display: none;
    }
  }
  .noMobileFlex {
    @media screen and (max-width: 500px) {
      display: unset;
    }
  }
  .verticalLine {
    @media screen and (max-width: 500px) {
      display: none;
    }
  }
  .align-center-text {
    text-align: center;
  }

  .margin-bottom {
    margin-bottom: 1rem;
  }
  .company-card-desc {
    width: 80%;
    margin-right: 1.4rem;
  }
  .mt {
    margin-top: 1.5rem;
  }

  button.ant-btn.ant-btn-round.highlight {
    background: #fff;
    &:hover,
    &:active,
    &:focus,
    &:visited {
      border: #000 0.2px solid;
      background-color: #000;
      color: #fff;
    }
  }
  h5.ant-typography,
  .ant-typography h5 {
    margin-bottom: 0;
  }
  * {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
  }
  .follow-btn {
    padding: 0.25rem;
    height: 36px;
    width: 8rem;
  }
  .space-pad div {
    padding: 0rem;
  }

  .m-all {
    margin-bottom: 1rem;
  }
  .shadow {
    border-color: transparent;
    box-shadow: 0px 16px 64px 0px #0000000a;
  }
  .ant-card-type-inner .ant-card-head {
    background: white;
  }
  .btn-follow {
    height: 2.5rem;
    padding: 0.25rem;
    width: 10rem;
  }
  @media screen and (min-width: 750px) {
    .company-card-desc {
      width: 60%;
      margin-right: 1.4rem;
    }
  }
  @media screen and (max-width: 750px) {
    .mob-direction-column {
      display: flex;
      flex-direction: column;
    }
  }
  .loadMoreButton {
    text-align: center;
    padding: 20px;
  }

  .people-card-wrap {
    margin-bottom: 12px;
  }
  @media screen and (max-width: 576px) {
    .people-card-body {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  @media screen and (min-width: 576px) {
    .people-card-body {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .pl-04 {
    padding-left: 0.4rem;
  }
  .p-0 {
    padding: 0%;
  }
  .ant-tabs-tab {
    width: 110px;
    height: 40px;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
  }
  .ant-tabs-ink-bar {
    background: none;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
    text-shadow: 0 0 0.25px currentColor;
    background: #000;
    border-radius: 100px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    border: none;
    border-bottom: none;
  }
  .ant-tabs-tab:hover {
    color: black;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    padding: 1rem 0rem 1rem 3rem;
  }
  .ant-tabs {
    .ant-tabs-content-holder {
      padding: 0rem;
      width: 100%;
      margin-top: 5rem;
      background-color: white;
    }
  }
  .py-10 {
    padding-block: 1rem;
  }

  .companies-grid {
    .ant-col {
      padding-bottom: 12px;
    }
    .company-card {
      background: #ffffff;
      box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      height: 20rem;
      header {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        text-align: center;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
      .company-content {
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 23px;
        padding-bottom: 40px;
      }
      .floater {
        position: absolute;
        text-align: center;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        top: 30%;
        .company-img-wrapper {
          height: 95px;
          width: 95px;
          border-radius: 50%;
          position: relative;
          background-color: white;
          .company-img {
            height: 80px;
            width: 80px;
            background-color: white;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -40px 0px 0px -40px;
          }
        }
      }
      .bold-text {
        font-weight: bold;
        font-size: 1.1rem;
        text-align: center;
        padding: 10px 20px 0px 20px;
      }
      .flex-center {
        display: flex;
        justify-content: center;
      }
    }
  }
  .w-100 {
    width: 100%;
  }
}
svg.map {
  path {
    stroke: #495057;
  }
}
svg.building {
  path {
    fill: #495057;
  }
}

.edit-profile-button {
  color: #3b7ddd;
  background: #ebf2fc;
  border: #ebf2fc;
  border-radius: 8px;
  width: 120px;
  font-size: 16px;
  font-weight: 600;
  &:hover {
    background: #ebf2fc;
  }
  @media screen and (max-width: 750px) {
    width: 100%;
  }
}

.company-tabs {
  padding: 0 6%;
  margin-top: 60px;
  min-height: 50vh;
  @media screen and (max-width: 750px) {
    margin-top: 0;
  }
  .ant-tabs-tab {
    width: 110px;
    justify-content: center;
    font-size: 16px;
  }
}

.post-image {
  text-align: center;
  height: 75%;
  padding-bottom: 15px;
  img {
    height: 100%;
    max-width: 100%;
    border-radius: 8px;
  }
}

.common-post-card-wrapper {
  padding: 10px;
  @media screen and (max-width: 750px) {
    padding: 10px 0px;
  }
  .common-post-card {
    .ant-card-body {
      padding: 15px;
      height: 25rem;
    }
  }
}

.image-text {
  height: 75%;
  padding-bottom: 15px;
  font-size: 16px;
}


.not-subscribed{
  background: #f2f4f7;
  height: 48px;
  p{
    font-size: 16px;
    color: #585E75;
    font-weight: 700;
  }
  span{
    color: #3B7DDD;
  }
}