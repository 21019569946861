.recommend-section {
    .rec-app-section {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
    -ms-overflow-style: none;
    // box-shadow: inset -10px 0 10px rgba(0, 0, 0, 0.3);
    // z-index: 100;

        .single-app {
            margin-right: 25px;
            margin-bottom: 20px;
        }
        .single-app:last-child {
            margin-right: 0;
        }
        .app-card {
            text-overflow: ellipsis;
            width: 265px;
            // z-index: 1;
        }
    }

    .rec-app-section::-webkit-scrollbar {
        background-color: #bfbfbf;
        height: 10px;
        border-radius: 10px;
    }

    .rec-app-section::-webkit-scrollbar-thumb {
        background-color: #bfbfbf;
        border-radius: 10px;
    }

    .rec-app-section::-webkit-scrollbar-track {
        background-color: #f5f5f5;
        border-radius: 10px;
    }

    .rec-app-section::-webkit-scrollbar-thumb:hover {
        background: #999999;
    }

    .rec-app-section::-moz-scrollbar {
        background-color: #bfbfbf;
        height: 10px;
        border-radius: 10px;
    }

    .rec-app-section::-moz-scrollbar-thumb {
        background-color: #bfbfbf;
        border-radius: 10px;
    }

    .rec-app-section::-moz-scrollbar-track {
        background-color: #f5f5f5;
        border-radius: 10px;
    }

    .rec-app-section::-moz-scrollbar-thumb:hover {
        background: #999999;
    }
}

.modal-recommender .app-card {
    width: 100%;
    margin-top: 16px;
}