@import '../../../styles/variables.less';
@import '../../../styles/global/variables.less';

.job-details {
  @media screen and (max-width: 920px) {
    .mobile-flex {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 920px) {
    .share-flex {
      padding-top: 20px;
      flex-direction: row;
      .ant-space-item {
        &:last-child {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
    }
  }
  .upper-job {
    height: 60px;
    padding: 0rem 5rem;
    @media screen and (max-width: 920px) {
      padding: 2rem 2rem;
    }
  }
  .collapse-wrapper {
    color: white;
    border: 0;
    .ant-collapse-header {
      background-color: #fff;
    }
  }
  .lower-job {
    padding: 1rem 5rem;
    @media screen and (max-width: 920px) {
      padding: 0rem 2rem;
      padding-bottom: 3rem;
    }
  }
  .m-all {
    margin: 1rem;
  }
  .shadow {
    border-color: transparent;
    box-shadow: 0 1px 12px rgb(0 0 0 / 9%);
  }
  .body-pad {
    padding: 2rem;
  }
  .bg-light {
    background-color: #fff;
  }
  .share-btn {
    display: flex;
    width: 60px;
    justify-content: space-around;
    align-items: center;
  }
  .follow-btn {
    padding: 0.25rem;
    width: 8rem;
    height: 2.5rem;
  }
  .loc-img {
    width: 100%;
    height: 50vh;
    @media screen and (max-width: 920px) {
      height: 20vh;
    }
  }
  .button-mobile-flex {
    flex-direction: column;
  }
  .text-blue {
    color: #3b7ddd;
    font-weight: bold;
  }
}

button.ant-btn.ant-btn-primary.viewDetailWeb.font-16.font-600.applied-btn {
  background: #ebf2fc;
  border-radius: 8px;
  color: #3b7ddd;
  border: #ebf2fc;
  width: 150px;
  &:hover {
    background: #ebf2fc;
    color: #3b7ddd;
  }
}

.recommend-similar-wrapper {
  width: 100%;
  padding-top: 30px;
  padding-right: 30px;
  .recommend-section {
    padding: 20px;
    // background-color: @white-color;
    border-radius: 12px;
    margin-bottom: 30px;
    .header-section {
      padding: 25px 0px 0px 20px;
      font-size: 20px;
      font-weight: 600;
      color: @black-color;
    }

    .recommend-divider {
      background-color: #eeeeee;
      margin: 20px 0px 20px 0px;
    }

    .job-section {
      // max-height: calc(100vh - 324px);
      // overflow-x: scroll;
      @media screen and (max-width: 921px) {
        flex-direction: column;
        align-items: center;
      }
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .single-job {
        @media screen and (max-width: 921px) {
          width: 80%;
        }
        margin-top: 20px;
        width: 30%;
      }
    }

    .job-card {
      height: 165px;
      padding: 20px;
      background-color: @white-color;
      box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.10);
      border-radius: 12px;

      .job-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: @black-color;
        text-overflow: ellipsis;
        // overflow: hidden;
        overflow-y: hidden;
        overflow-x: hidden;
        white-space: nowrap;
        margin-bottom: 10px;
      }

      .link {
        color: #495057;
        font-size: 14px;
        text-decoration: underline;
        margin-left: 7px;
        width: 250px;
        height: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
      }

      .job-card-divider {
        background-color: #eeeeee;
        margin: 15px 0px 15px 0px;
      }

      .footer {
        .bold-text {
          font-size: 16px;
          font-weight: 400;
          color: @black-color;
        }
        .normal-text {
          font-size: 16px;
          color: @black-color;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
