@import '../../../styles/variables.less';
@import '../../../styles/global/variables.less';

.individual-jobs {
  padding: 3% 4%;
  background-color: white;
  height: 100%;
  min-height: 100vh;
  @media screen and (max-width: 1000px) {
    width: 100vw;
  }
  .ant-card-type-inner .ant-card-head {
    background: white;
  }
  .viewDetailWeb {
    @media screen and (max-width: 920px) {
      display: none;
    }
  }
  .btn-follow {
    padding: 0.25rem;
    width: 10rem;
    height: 2.5rem;
  }
  .viewDetailMobile {
    @media screen and (min-width: 920px) {
      display: none;
    }
    .btn-follow {
      padding: 0.25rem;
      width: 100%;
      height: 2.5rem;
    }
  }
  .noMobileFlex {
    @media screen and (max-width: 750px) {
      display: unset;
    }
  }
  .verticalLine {
    @media screen and (max-width: 750px) {
      display: none;
    }
  }
  .align-center-text {
    text-align: center;
  }
  .w-100 {
    width: 100%;
  }
  .shadow {
    border-color: transparent;
    box-shadow: 0 4px 12px rgb(0 0 0 / 9%);
  }
  * {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
  }
  .margin-bottom {
    margin-bottom: 1rem;
  }
  .pl {
    padding-left: inherit;
  }
  .grey-btn {
    background-color: #cccccc;
    border-color: #cccccc;
    color: #000;
  }
  .text-blue {
    color: #3b7ddd;
    font-weight: bold;
  }
  .round-button {
    .ant-btn-round {
      height: 35px;
      padding: 8px 20px;
      font-size: 14px;
      border-radius: 45px;
    }
  }
  button.ant-btn.ant-btn-round.highlight {
    background: #fff;
    &:hover,
    &:active,
    &:focus {
      border: #000 0.2px solid;
      background-color: #000;
      color: #fff;
    }
  }
  @media screen and (max-width: 750px) {
    .img {
      height: 70px;
      width: 70px;
    }
    .heading {
      color: #000;
      margin-bottom: 0%;
      width: 100%;
    }
    .mt-mobile {
      margin-block: 1rem;
    }
    .flex-center-mobile {
      justify-content: center;
      display: flex;
      align-items: stretch;
    }
  }
  @media screen and (min-width: 750px) {
    .img {
      height: 85px;
      width: 85px;
    }
    .heading {
      color: #000;
      margin-bottom: 0%;
      width: 50%;
    }
  }
  .ant-tabs-tab {
    justify-content: center;
    font-size: 16px;
    width: 150px;
    &:hover,
    &:focus,
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-remove:active {
      color: #000;
    }
  }
  .ant-tabs-ink-bar {
    background: none;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
    text-shadow: 0 0 0.25px currentColor;
    background: #000;
    border-radius: 100px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    border: none;
    border-bottom: none;
  }
  .ant-card-type-inner .ant-card-head {
    background: white;
  }
  .ant-card.ant-card-bordered.ant-card-type-inner.applied-card {
    box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    border: none;
    padding: 20px;
    position: relative;
  }

  .rejected-btn {
    border: 1px solid #ea4335;
    color: #ea4335;
    cursor: unset;
    &:hover {
      border: 1px solid #ea4335;
      color: #ea4335;
    }
  }
  .accepted-btn {
    color: #3b7ddd;
    background: #ebf2fc;
    cursor: unset;
  }
}

.recommend-apps-wrapper {
  @media screen and (max-width: 921px) {
    display: none;
  }
  // width: 100%;
  .recommend-section {
    // background-color: @white-color;
    border-radius: 12px;
    margin-bottom: 30px;

    .header-section {
      padding: 25px 0px 0px 20px;
      font-size: 20px;
      font-weight: 600;
      color: @black-color;
    }

    .recommend-divider {
      background-color: #eeeeee;
      margin: 20px 0px 20px 0px;
    }
  }
}

.app-recommender-btn {
  @media screen and (min-width: 920px) {
    display: none;
  }
  display: block;
}