@import '../../styles/variables.less';

.input-component {
  .ant-input {
    //color: @field-color;
    font-weight: 500;
    background-color: @field-background-color;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid @border-color;
    border-radius: unset;
  }
  .ant-input::placeholder {
    color: #8b8d95;
  }

  .ant-input:placeholder-shown {
    background-color: @field-background-color;
  }
}

.ant-form-item-has-error .price-component.ant-input-affix-wrapper,
.ant-form-item-has-error .input-component.ant-input-affix-wrapper:hover {
  //border: 1px solid @field-error;
  background-color: @field-background-color;
}

.password-input.ant-input-affix-wrapper.ant-input-password {
  background-color: @field-background-color;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid @border-color;
  border-radius: 6px;
  .ant-input {
    background-color: @field-background-color;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid @border-color;
  }
}

.password-input.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid @border-color;
}

.input-component.ant-input-affix-wrapper {
  border: 1px solid @border-color;
  background-color: @field-background-color;
  border-radius: 6px;
  height: 32px;
  padding: 8px;
  transition: none;
}

.input-component.ant-input-affix-wrapper:focus {
  border: 1px solid @border-color;
  box-shadow: none; // can remove if  user not identify which input is currently active
  padding: 9px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid @border-color;
  box-shadow: none; // can remove if  user not identify which input is currently active
}
.input-component.ant-input-affix-wrapper-disabled:hover {
  border: none;
  padding: 9px;
}

.input-component.ant-input-affix-wrapper > input.ant-input {
  background-color: @field-background-color;
  box-shadow: none;
}
.ant-form-item-has-error {
  .input-component.ant-input-affix-wrapper,
  .input-component.ant-input-affix-wrapper:hover {
    background-color: @field-background-color;
  }
  .input-component.ant-input-affix-wrapper-disabled,
  .input-component.ant-input-affix-wrapper-disabled:hover {
    padding: 9px;
    border: none;
    background-color: @field-background-color;
  }
  .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background-color: @field-background-color;
  }
  .input-component.ant-input-affix-wrapper-focused {
    border: 1px solid @field-error;
    background-color: @field-background-color;
  }
  .input-component.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid @field-error;
    background-color: @field-background-color;
  }

  .password-input.ant-input-affix-wrapper.ant-input-password {
    border: 1px solid @field-error;
    background-color: @field-background-color;
  }
}

.ant-form-item-has-error
  :not(.input-component.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.input-component.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.input-component.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.input-component.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: @field-background-color;
}

.input-component.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: @border-color;
  z-index: 1;
  background-color: @field-background-color;
}
