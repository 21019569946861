.under-review {
  height: 100%;
  @media screen and (max-width: 375px) {
    width: 400px !important;
  }
  img.logo {
    width: 7em;
    height: auto;
    margin-left: 25px;
    margin-top: 25px;
    @media screen and (max-width: 550px) {
      margin-top: 0;
    }
  }
  .spacer {
    width: 20px;
  }
  &-image-text {
    height: 100%;
    width: 20%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: fixed;
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2)
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0) 24.01%, rgba(0, 0, 0, 0.39) 100%),
      url(~assets/images/signUpImage.jpg);
    backdrop-filter: blur(4px);
    @media screen and (max-width: 768px) {
      width: 30%;
    }
    h1 {
      color: @white-color;
      font-weight: bold;
      padding: 40px 40px;
      display: flex;
      justify-content: flex-end;
      bottom: 4%;
      position: absolute;
    }
  }
  &-detail-section {
    padding: 70px 75px;
    width: 100%;
    margin-left: 20%;
    @media screen and (max-width: 768px) {
      margin-left: 30%;
      padding: 50px;
    }
    @media screen and (max-width: 550px) {
      margin-left: 0;
      padding: 35px;
      background: @white-color;
    }
    @media screen and (max-width: 375px) {
      padding: 20px;
    }
    h1 {
      font-weight: 600;
      font-size: xx-large;
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
    }
    p.mt-25 {
      font-size: 18px;
      color: @black-color;
    }
    p.under-review-text {
      font-weight: 500;
      font-size: 23.04px;
      color: #3b7ddd;
      margin-top: 25px;
    }
  }
}
