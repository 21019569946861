.search {
  background-color: #fff;
  position: relative;
  .ant-tabs-nav {
    position: fixed !important;
    z-index: 100;
    width: 100%;
    background: white;
  }
  .people-grid {
    padding: 0rem 4rem;
    @media screen and (max-width: 750px) {
      padding: 0rem 2rem;
    }
  }
  .experience-cover {
    margin: 0px;
    margin-top: 2px;
    .building {
      height: 17px;
    }
  }

  @media screen and (max-width: 750px) {
    .company {
      padding-right: 36px;
    }
    .img {
      height: 70px;
      width: 70px;
    }
  }
  @media screen and (min-width: 750px) {
    .img {
      height: 85px;
      width: 85px;
    }
  }
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      &:first-child {
        width: 200px;
        cursor: unset;
        color: #000;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  .viewDetailWeb {
    @media screen and (max-width: 750px) {
      display: none;
    }
    .company,
    .job {
      width: 80vw;
    }
  }
  .viewDetailMobile {
    @media screen and (min-width: 749px) {
      display: none;
    }
  }
  .noMobileFlex {
    @media screen and (max-width: 500px) {
      display: unset;
    }
  }
  .verticalLine {
    @media screen and (max-width: 500px) {
      display: none;
    }
  }
  .align-center-text {
    text-align: center;
  }

  .margin-bottom {
    margin-bottom: 1rem;
  }
  .company-card-desc {
    width: 80%;
    margin-right: 1.4rem;
  }
  .mt {
    margin-top: 1.5rem;
  }

  button.ant-btn.ant-btn-round.highlight {
    background: #fff;
    &:hover,
    &:active,
    &:focus,
    &:visited {
      border: #000 0.2px solid;
      background-color: #000;
      color: #fff;
    }
  }
  h5.ant-typography,
  .ant-typography h5 {
    margin-bottom: 0;
  }
  * {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
  }
  .follow-btn {
    padding: 0.25rem;
    height: 36px;
    width: 8rem;
  }
  .space-pad div {
    padding: 0rem;
  }
  .space-very-small-h {
    width: 5px;
  }
  .space-small-h {
    width: 10px;
  }
  .space-small-v {
    height: 10px;
  }
  .m-all {
    margin-bottom: 1rem;
  }
  .shadow {
    border-color: transparent;
    box-shadow: 0px 16px 64px 0px #0000000a;
  }
  .ant-card-type-inner .ant-card-head {
    background: white;
  }
  .btn-follow {
    height: 2.5rem;
    padding: 0.25rem;
    width: 10rem;
  }
  @media screen and (min-width: 750px) {
    .company-card-desc {
      width: 60%;
      margin-right: 1.4rem;
    }
  }
  @media screen and (max-width: 750px) {
    .mob-direction-column {
      display: flex;
      flex-direction: column;
    }
  }
  .loadMoreButton {
    text-align: center;
    padding: 20px;
  }

  .people-card-wrap {
    margin-bottom: 12px;
  }
  @media screen and (max-width: 576px) {
    .people {
      .people-card-body {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
  @media screen and (min-width: 576px) {
    .people {
      .people-card-body {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
  .pl-04 {
    padding-left: 0.4rem;
  }
  .p-0 {
    padding: 0%;
  }
  .ant-tabs-tab {
    width: 110px;
    height: 40px;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
  }
  .ant-tabs-ink-bar {
    background: none;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
    text-shadow: 0 0 0.25px currentColor;
    background: #000;
    border-radius: 100px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    border: none;
    border-bottom: none;
  }
  .ant-tabs-tab:hover {
    color: black;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    padding: 1rem 0rem 1rem 3rem;
  }
  .ant-tabs {
    .ant-tabs-content-holder {
      padding: 0rem;
      width: 100%;
      margin-top: 5rem;
      background-color: white;
    }
  }
  .py-10 {
    padding-block: 1rem;
  }
  .companies-grid {
    padding: 0rem 4rem;
    @media screen and (max-width: 750px) {
      padding: 0rem 2rem;
    }
    .ant-col {
      padding-bottom: 12px;
    }
    .company-card {
      background: #ffffff;
      box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      height: 20rem;
      header {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        text-align: center;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
      .company-content {
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 23px;
        padding-bottom: 40px;
      }
      .floater {
        position: absolute;
        text-align: center;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        top: 30%;
        .company-img-wrapper {
          height: 95px;
          width: 95px;
          border-radius: 50%;
          position: relative;
          background-color: white;
          .company-img {
            height: 80px;
            width: 80px;
            background-color: white;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -40px 0px 0px -40px;
          }
        }
      }
      .bold-text {
        font-weight: bold;
        font-size: 1.1rem;
        text-align: center;
        padding: 10px 20px 0px 20px;
      }
      .flex-center {
        display: flex;
        justify-content: center;
      }
    }
  }
  .w-100 {
    width: 100%;
  }
}

.job {
  padding: 18px 80px;
  .filters {
    .ant-select-selector {
      width: 220px !important;
      border-radius: 100px !important;
      margin-bottom: 5px;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow {
      color: #000;
      font-weight: 600;
      font-size: 16px;
    }
    span.ant-select-arrow {
      color: #000;
      top: 45%;
    }
    span.ant-select-clear {
      color: #000;
      top: 45%;
    }
  }
}

.ant-popover.ant-popover-placement-bottom {
  width: 15%;
}

button.ant-btn.slider {
  width: 220px;
  border-radius: 100px;
  color: #bfbfbf;
  font-weight: 600;
  font-size: 16px;
  height: 36px;
  padding: 5px 11px;
  span {
    display: flex;
  }
}
