.company-profile {
  // height: auto !important;
  background-color: #f5f5f5 !important;
  img.logo {
    width: 7em;
    height: auto;
    margin-left: 25px;
    margin-top: 25px;
    @media screen and (max-width: 550px) {
      margin-top: 0;
    }
  }
  &-image-text {
    height: 100%;
    width: 20%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: fixed;
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2)
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0) 24.01%, rgba(0, 0, 0, 0.39) 100%),
      url(~assets/images/signUpImage.jpg);
    backdrop-filter: blur(4px);
    @media screen and (max-width: 768px) {
      width: 30%;
    }
    h1 {
      color: @white-color;
      font-weight: bold;
      padding: 40px 40px;
      display: flex;
      justify-content: flex-end;
      bottom: 4%;
      position: absolute;
    }
  }
  &-detail-section {
    padding: 70px 75px;
    width: 60%;
    margin-left: 20%;
    @media screen and (max-width: 768px) {
      margin-left: 30%;
      padding: 50px;
      width: 100%;
    }
    @media screen and (max-width: 550px) {
      margin-left: 0;
      padding: 30px;
      background: @white-color;
    }

    input.ant-input {
      height: 40px;
    }
    h1 {
      font-weight: 600;
      font-size: xx-large;
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
      color: #212429;
    }
    .profile-image {
      border: 1px solid #dde2e5;
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }
    .upload-button {
      background: #eeeeee;
      border-radius: 8px;
      color: #000000;
      height: 48px;
      font-weight: 600;
      &:active,
      &:hover {
        border-color: #eeeeee;
      }
    }
  }
  &-form {
    margin-top: 34px;
    label {
      color: #000000;
    }
    .add-experience-button {
      background: #eeeeee;
      border-radius: 100px;
      color: #000000;
      border: none;
    }
    .company-profile-detail-section input.ant-input {
      height: 56px;
    }
    .ant-picker {
      height: 56px;
      width: 100%;
    }
    .ant-checkbox-inner {
      border-radius: 0;
    }
    .experience-block {
      background: rgba(255, 255, 255, 0.507);
      border-radius: 20px;
      border: 1px solid rgba(247, 247, 247, 0.39);
      padding: 15px 30px;
      @media screen and (max-width: 768px) {
        padding: 15px 15px;
      }
      @media screen and (max-width: 550px) {
        background: #eeeeee;
      }
    }
    .ant-select.tag-input.ant-select-single.ant-select-allow-clear.ant-select-show-arrow {
      width: 50%;
      height: 44px;
      @media screen and (max-width: 550px) {
        width: 100%;
      }
    }
    .ant-select-selector {
      height: 100%;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
    }
    span.ant-tag.edit-tag {
      height: 44px;
      margin-top: 10px;
      background: #eeeeee;
      border-radius: 100px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      .ant-tag-close-icon {
        font-size: 18px;
      }
      @media screen and (max-width: 550px) {
        margin-top: 5px;
        display: flex;
      }
    }
    .resume-upload {
      display: flex;
      width: auto;
      .ant-upload.ant-upload-select.ant-upload-select-text {
        width: 100%;
      }
    }
    .resume-button {
      height: 60px;
    }
    .ant-select.ant-select-single.ant-select-show-arrow {
      height: 56px;
      .ant-select-selector {
        height: 100%;
      }
    }
  }
}

input.location-input {
  height: 56px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 7.9px 11px;
  &:focus-visible {
    outline: none;
  }
}

.location-listing {
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin-top: 5px;
  padding: 10px;
  list-style-type: none;
  cursor: pointer;
  z-index: 1000;
  position: absolute;
  width: 100%;
  li {
    margin-top: 2px;
    &:hover {
      background: #eeeeee;
    }
  }
}

input#industry {
  height: 100%;
}

.payment-modal {
  .ant-modal-body {
    padding: 24px 32px;
  }
  .select-year {
    padding: 20px 0;
    .ant-card-body {
      padding: 16px 20px;
      cursor: pointer;
      border-radius: 8px;
      h1 {
        color: #acb5bd;
        font-weight: 700;
      }
      h2 {
        color: #acb5bd;
        font-weight: 500;
      }
    }
  }
  .ant-card.ant-card-bordered.selected-plan {
    background: linear-gradient(261.07deg, #2b2b2b 0%, #000000 100%);
    h1,
    h2 {
      color: #ffffff;
    }
  }

  .access-list {
    margin-top: 12px;
    p {
      display: flex;
      align-items: center;
      color: #374957;
      font-size: 16px;
      font-weight: 700;
      svg {
        margin-right: 10px;
      }
    }
  }
  .note-card {
    margin-top: 28px;
    background: #f2f4f7;
    border-radius: 8px;
    .ant-card-body {
      padding: 16px 12px;
    }
    h3 {
      font-weight: 600;
      color: #000000;
    }
    li {
      margin-top: 5px;
      font-weight: 600;
      font-size: 16px;
      color: #374957;
    }
  }
  button.ant-btn.purchase-button {
    background: #3b7ddd;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    margin-top: 20px;
    width: 100%;
    height: 56px;
  }
}

.ant-modal.payment-modal {
  width: 800px !important;
}

.cancel-subscription-modal {
  .ant-modal-body {
    h2 {
      font-weight: 700;
      font-size: 24px;
      color: #000000;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      color: #000000;
    }
    button.ant-btn {
      width: 100%;
      background: #f44f40;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      height: 56px;
      margin-top: 20px;
    }
  }
}

.update-pricing-modal {
  width: 700px !important;
  .size-color {
    color: #3b7ddd;
  }
  .ant-modal-body {
    h2 {
      font-weight: 700;
      font-size: 24px;
      color: #000000;
    }

    .ant-row {
      margin-top: 28px;
      .ant-card-body {
        padding: 16px 12px;
        h2 {
          font-weight: 600;
          font-size: 18px;
          color: #acb5bd;
        }
        h1 {
          color: #000000;
          font-weight: 700;
          font-size: 32px;
          margin-bottom: 0;
        }
      }
    }
    p {
      font-weight: 500;
      font-size: 16px;
      color: #000000;
    }
    button.ant-btn {
      width: 100%;
      background: #3b7ddd;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      height: 56px;
      margin-top: 20px;
    }
  }
}
