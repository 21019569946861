@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.company-feed-module {
  ::-webkit-scrollbar {
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: #7f7f7f;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #7f7f7f;
  }
  .wrapper-class {
    display: flex;
    overflow-y: scroll;
    height: 100vh;
    @media screen and (min-width: 320px) and (max-width: 991px) {
      display: unset;
    }

    .feed-sidebar {
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      background: transparent;
      width: 15%;
      text-align: right;
      a {
        color: @black-color;
        font-size: 16px;
        font-weight: 400;
      }
      .menu {
        margin-top: 10%;
      }
      @media screen and (min-width: 320px) and (max-width: 991px) {
        display: none;
      }
    }
    .upload-post-section-upper {
      width: 38%;
      padding-top: 12px;
      padding-left: 30px;
      padding-right: 30px;
      @media screen and (min-width: 320px) and (max-width: 991px) {
        width: 100%;
      }
    }

    .post-section {
      padding-bottom: 20px;
      .single-post {
        margin-top: 20px;
      }
    }

    .post-wrapper {
      background: @white-color;
      border-radius: 8px;
      padding: 15px 20px;
      .post-header {
        .text-section {
          margin-left: 10px;
          display: flex;
          flex-direction: column;

          .title {
            color: @black-color;
            font-size: 14px;
            font-weight: 600;
          }
          .sub-title {
            color: #495057;
            font-size: 13px;
          }
        }

        .connect-button {
          margin-right: 15px;
          border-radius: 8px;
          height: 36px;
          width: 100px;
        }
        .connect-button.ant-btn {
          padding: unset;
        }
      }
      .image-text {
        margin-top: 20px;
        color: @black-color;
        font-size: 14px;
        font-weight: 500;
        word-break: break-word;
        padding: 5px;
        cursor: pointer;
      }

      .only-text {
        color: @black-color;
        font-size: 20px;
        font-weight: 500;
      }

      .post-image {
        text-align: center;
        cursor: pointer;
        img {
          margin-top: 20px;
          height: auto;
          width: 100%;
          border-radius: 8px;
        }
      }

      .post-video {
        text-align: center;
        cursor: pointer;
        .video {
          margin-top: 20px;
          width: 100%;
          // height: 50vh;
          // border-radius: 8px;
        }
      }

      .like-section {
        margin-top: 24px;
        // margin-left: 10px;
        width: 100%;
        .image {
          cursor: pointer;
          height: 20px;
          width: 20px;
        }

        .ml-20 {
          margin-left: 20px;
        }
        .info-section {
          color: #495057;
          font-weight: 500;
          margin-left: 7px;
        }
      }

      .post-divider {
        margin: 15px 0 19px 0;
        background-color: #eeeeee;
      }

      .bio-section {
        color: #495057;
        font-size: 14px;
        //max-height: 100px;
        // overflow-y: hidden;
        // text-overflow: ellipsis;
      }
      .mt-20 {
        margin-top: 20px;
      }
    }

    .recommend-wrapper {
      width: 25%;
      padding-top: 30px;
      padding-right: 30px;
      .recommend-section {
        padding: 20px;
        background-color: @white-color;
        border-radius: 12px;
        @media screen and (min-width: 320px) and (max-width: 991px) {
          display: none;
        }
        .header-section {
          padding: 0px 0px 0px 20px;
          font-size: 20px;
          font-weight: 600;
          color: @black-color;
        }

        .recommend-divider {
          background-color: #eeeeee;
          margin: 20px 0px 20px 0px;
        }

        .job-section {
          // max-height: calc(100vh - 324px);
          // overflow-y: scroll;
          .single-job {
            margin-top: 20px;
          }
        }

        .job-card {
          height: 165px;
          padding: 20px;
          background-color: @white-color;
          box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.04);
          border-radius: 12px;

          .job-title {
            font-size: 16px;
            font-weight: 600;
            color: @black-color;
            line-height: 24px;
            text-overflow: ellipsis;
            // overflow: hidden;
            overflow-y: hidden;
            overflow-x: hidden;
            white-space: nowrap;
          }

          .link {
            color: #495057;
            font-size: 14px;
            text-decoration: underline;
            margin-left: 7px;
            text-overflow: ellipsis;
            width: 200px;
            height: 16px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;
          }

          .job-card-divider {
            background-color: #eeeeee;
            margin: 15px 0px 15px 0px;
          }

          .footer {
            .bold-text {
              font-size: 16px;
              font-weight: 400;
              color: @black-color;
            }
            .normal-text {
              font-size: 14px;
              color: @black-color;
            }
          }
        }
      }
    }
  }
}

.comment-pop-over {
  .ant-popover-inner {
    background-color: violet;
  }
}

.search-button {
  height: 30px;
  margin-top: 5px;
  margin-left: 5px;
}
