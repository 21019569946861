@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

//* new-job.less (post job)

.new-job {
  padding: 3% 4%;
  background-color: @white-color;
  @media screen and (max-width: 1000px) {
    width: 100vw;
  }
  .header {
    margin-bottom: 40px;
    h1 {
      font-weight: 700;
      font-size: 40px;
      margin-bottom: 10px;
    }
  }
  .ant-select.ant-select-single.ant-select-show-arrow {
    height: 40px;
    .ant-select-selector {
      height: 100%;
    }
  }
  .margin-10 {
    margin: 10px;
    @media screen and (max-width: 550px) {
      margin: 0% 50%;
    }
  }
  .salary.ant-form-item {
    @media screen and (max-width: 550px) {
      margin-bottom: 0px;
    }
  }
  .salary-label {
    margin-bottom: 8px;
  }
  span.ant-tag.edit-tag {
    height: 44px;
    margin-top: 10px;
    background: #eeeeee;
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    .ant-tag-close-icon {
      font-size: 18px;
    }
    @media screen and (max-width: 550px) {
      margin-top: 5px;
      display: flex;
    }
  }
}
