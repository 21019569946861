.user-profile {
  .space-very-small-h {
    width: 5px;
  }
  .space-small-h {
    width: 10px;
  }
  .space-small-v {
    height: 10px;
  }
  .space-very-small-v {
    height: 5px;
  }
  .space-large-v {
    height: 20px;
  }
  .user-profile-upper-mobile {
    display: none;
    height: 80vh;
    position: relative;
    @media screen and (max-width: 750px) {
      display: block;
    }
    .cover-container {
      height: 35%;
      background-size: cover;
      background-position: center;
    }
    .user-detail-container {
      height: 65%;
      padding: 10px 25px;
    }
    .user-profile-mobile {
      position: absolute;
      top: 29%;
      left: 5%;
    }
  }
  .user-profile-upper-web {
    @media screen and (max-width: 750px) {
      display: none;
    }
    .user-details {
      padding-left: 6%;
      padding-top: 4%;
      width: 55%;
      @media screen and (max-width: 1000px) {
        width: 65%;
      }
      h1 {
        font-size: 30px;
      }
      p {
        font-size: 15px;
      }
    }
    .cover-container {
      width: 45%;
      position: relative;
      height: 444px;
      background-size: cover;
      background-position: center;
      @media screen and (max-width: 1000px) {
        width: 45%;
      }

      button.ant-btn.change-cover-btn {
        position: absolute;
        top: 90%;
        left: 80%;
        transform: translate(-50%, -50%);
        background: #00000033;
        backdrop-filter: blur(50px);
        border-radius: 100px;
        color: white;
        font-size: 16px;
        padding: 12px 24px;
        border: none;
        cursor: pointer;
        text-align: center;
      }
    }
    .cover-container .btn:hover {
      background-color: black;
    }
  }

  .loadMoreButton {
    text-align: center;
    padding: 20px;
  }
  background: @white-color;
  height: 100%;
  .profile-image {
    border: 1px solid #dde2e5;
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .post-video {
    height: 75%;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    background-color: #000;
    .video {
      height: auto;
      width: 100%;
    }
  }
  .ant-divider.ant-divider-horizontal.post-divider {
    margin: 3px 0;
  }
  .profile-post {
    padding: 10px;
    @media screen and (max-width: 750px) {
      padding: 0px;
    }
  }
  .about {
    .resume-card {
      background: white;
      box-shadow: 0px 16px 64px 20px rgb(0 0 0 / 4%);
      border-radius: 12px;
      padding: 10px;
      height: 50px;
    }

    .outer-card {
      padding: 10px;
      .shadow {
        border-color: transparent;
        box-shadow: 0 1px 12px rgb(0 0 0 / 9%);
        .ant-card {
          height: 180px;
          .ant-card-body {
            padding: 20px;
            height: 100%;
            width: 100%;
            .company-name {
              margin-left: 5px;
              font-weight: 700;
              display: flex;
              align-items: center;
              margin-bottom: 0px;
            }
            .company-header {
              display: flex;
              .company-img-wrapper {
                height: 45px;
                width: 45px;
                border-radius: 50%;
                position: relative;
                background-color: red;
                .company-img {
                  height: 40px;
                  width: 40px;
                  background-color: white;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
                  border-radius: 50%;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  margin: -20px 0px 0px -20px;
                }
              }
            }
          }
        }
        .ant-card.adder {
          .ant-card-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
        .ant-card.about-card {
          .ant-card-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .body-style {
      width: 100%;
    }

    .btn-follow {
      padding: 0.25rem;
      width: 8rem;
      height: 2.5rem;
    }
    .btn-grey {
      background: #eeeeee;
      border-radius: 100px;
      border-color: #cccccc;
      color: #000;
    }
    .btn-adder {
      background: #dde2e5;
    }
  }
  .popover-design {
    margin-left: 10px;
    border: 1px solid rgb(63, 54, 54);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    @media screen and (max-width: 750px) {
      margin-right: 0;
    }
    :hover {
      background: rgba(59, 125, 221, 0.1);
      border: 1px solid #3b7ddd;
    }
  }
}

.connection-section {
  background-color: #fff;
  position: relative;
  padding: 10px 10px;
  .ant-tabs-nav {
    position: fixed !important;
    z-index: 100;
    width: 100%;
    background: white;
  }

  @media screen and (max-width: 750px) {
    .company {
      padding-right: 0px;
    }
    .img {
      height: 70px;
      width: 70px;
    }
  }
  @media screen and (min-width: 750px) {
    .img {
      height: 85px;
      width: 85px;
    }
  }
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      &:first-child {
        width: 200px;
        cursor: unset;
        color: #000;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  .viewDetailWeb {
    @media screen and (max-width: 750px) {
      display: none;
    }
    .company,
    .job {
      width: 80vw;
    }
  }
  .viewDetailMobile {
    @media screen and (min-width: 749px) {
      display: none;
    }
  }
  .noMobileFlex {
    @media screen and (max-width: 500px) {
      display: unset;
    }
  }
  .verticalLine {
    @media screen and (max-width: 500px) {
      display: none;
    }
  }
  .align-center-text {
    text-align: center;
  }

  .margin-bottom {
    margin-bottom: 1rem;
  }
  .company-card-desc {
    width: 80%;
    margin-right: 1.4rem;
  }
  .mt {
    margin-top: 1.5rem;
  }

  button.ant-btn.ant-btn-round.highlight {
    background: #fff;
    &:hover,
    &:active,
    &:focus,
    &:visited {
      border: #000 0.2px solid;
      background-color: #000;
      color: #fff;
    }
  }
  h5.ant-typography,
  .ant-typography h5 {
    margin-bottom: 0;
  }
  * {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
  }
  .follow-btn {
    padding: 0.25rem;
    height: 36px;
    width: 8rem;
  }
  .space-pad div {
    padding: 0rem;
  }

  .m-all {
    margin-bottom: 1rem;
  }
  .shadow {
    border-color: transparent;
    box-shadow: 0px 16px 64px 0px #0000000a;
  }
  .ant-card-type-inner .ant-card-head {
    background: white;
  }
  .btn-follow {
    height: 2.5rem;
    padding: 0.25rem;
    width: 10rem;
  }
  @media screen and (min-width: 750px) {
    .company-card-desc {
      width: 60%;
      margin-right: 1.4rem;
    }
  }
  @media screen and (max-width: 750px) {
    .mob-direction-column {
      display: flex;
      flex-direction: column;
    }
  }
  .loadMoreButton {
    text-align: center;
    padding: 20px;
  }

  .people-card-wrap {
    margin-bottom: 12px;
  }
  @media screen and (max-width: 576px) {
    .people-card-body {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  @media screen and (min-width: 576px) {
    .people-card-body {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .pl-04 {
    padding-left: 0.4rem;
  }
  .p-0 {
    padding: 0%;
  }
  .ant-tabs-tab {
    width: 110px;
    height: 40px;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
  }
  .ant-tabs-ink-bar {
    background: none;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
    text-shadow: 0 0 0.25px currentColor;
    background: #000;
    border-radius: 100px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    border: none;
    border-bottom: none;
  }
  .ant-tabs-tab:hover {
    color: black;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    padding: 1rem 0rem 1rem 3rem;
  }
  .ant-tabs {
    .ant-tabs-content-holder {
      padding: 0rem;
      width: 100%;
      margin-top: 5rem;
      background-color: white;
    }
  }
  .py-10 {
    padding-block: 1rem;
  }

  .companies-grid {
    .ant-col {
      padding-bottom: 12px;
    }
    .company-card {
      background: #ffffff;
      box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      height: 20rem;
      header {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        text-align: center;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
      .company-content {
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 23px;
        padding-bottom: 40px;
      }
      .floater {
        position: absolute;
        text-align: center;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        top: 30%;
        .company-img-wrapper {
          height: 95px;
          width: 95px;
          border-radius: 50%;
          position: relative;
          background-color: white;
          .company-img {
            height: 80px;
            width: 80px;
            background-color: white;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -40px 0px 0px -40px;
          }
        }
      }
      .bold-text {
        font-weight: bold;
        font-size: 1.1rem;
        text-align: center;
        padding: 10px 20px 0px 20px;
      }
      .flex-center {
        display: flex;
        justify-content: center;
      }
    }
  }
  .w-100 {
    width: 100%;
  }
}
svg.map {
  path {
    stroke: #495057;
  }
}
svg.building {
  path {
    fill: #495057;
  }
}

.edit-profile-button {
  color: #3b7ddd;
  background: #ebf2fc;
  border: #ebf2fc;
  border-radius: 8px;
  width: 120px;
  font-size: 16px;
  font-weight: 600;
  &:hover {
    background: #ebf2fc;
  }
  @media screen and (max-width: 750px) {
    width: 100%;
  }
}

.follow-button {
  border-radius: 8px;
  width: 120px;
  font-size: 16px;
  font-weight: 600;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
}

.user-tabs {
  padding: 0 6%;
  margin-top: 60px;
  min-height: 50vh;
  @media screen and (max-width: 750px) {
    margin-top: 0;
  }
  .ant-tabs-tab {
    width: 110px;
    justify-content: center;
    font-size: 16px;
  }
}

.post-image {
  text-align: center;
  height: 75%;
  padding-bottom: 15px;
  img {
    height: 100%;
    max-width: 100%;
    border-radius: 8px;
  }
}

.common-post-card-wrapper {
  padding: 10px;
  @media screen and (max-width: 750px) {
    padding: 10px 0px;
  }
  .common-post-card {
    .ant-card-body {
      padding: 15px;
      height: 25rem;
    }
  }
}

.image-text {
  height: 75%;
  padding-bottom: 15px;
  font-size: 16px;
}

.ant-upload.ant-upload-select.ant-upload-select-text {
  height: 100%;
  width: 100%;
}

.ant-popover.notification-popover.ant-popover-placement-bottomRight {
  width: 480px !important;
  right: auto !important;
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}

.notification-content {
  min-height: 500px;
  h2 {
    padding: 15px 10px 0 15px;
  }
}

.notification-card {
  // padding: 10px;
  .ant-card.ant-card-bordered {
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100%;
    margin-top: 0px;
    border-radius: 0;
  }
  .ant-card-body {
    display: flex;
    width: 100%;
    padding: 10px;
    justify-content: center;
  }
  span.ant-avatar.ant-avatar-circle.ant-avatar-image {
    width: 40px;
    height: 40px;
  }
}

.ant-popover.notification-popover.ant-popover-placement-bottomRight {
  width: 480px !important;
  right: auto !important;
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}

.notification-content {
  min-height: 500px;
  h2 {
    padding: 15px 10px 0 15px;
  }
}

.notification-card {
  // padding: 10px;
  .ant-card.ant-card-bordered {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
  .ant-card-body {
    padding: 5px;
  }
  span.ant-avatar.ant-avatar-circle.ant-avatar-image {
    width: 40px;
    height: 40px;
  }
}
