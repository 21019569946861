.companies {
  padding: 3% 4%;
  background-color: white;
  .companies-card {
    background: #ffffff;
    box-shadow: 0px 16px 64px rgb(0 0 0 / 4%);
    border-radius: 12px;
    .ant-card-body {
      @media screen and (max-width: 920px) {
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    width: 100vw;
  }
  .ant-card-type-inner .ant-card-head {
    background: white;
  }
  .viewDetailWeb {
    @media screen and (max-width: 920px) {
      display: none;
    }
  }
  .btn-follow {
    padding: 0.25rem;
    width: 10rem;
    height: 2.5rem;
    font-size: 16px;
    font-weight: 700;
  }
  .btn-following {
    padding: 0.25rem;
    width: 10rem;
    height: 2.5rem;
    color: #3b7ddd;
    background: #ebf2fc;
    font-size: 16px;
    font-weight: 700;
  }
  .viewDetailMobile {
    @media screen and (min-width: 920px) {
      display: none;
    }
    .btn-follow {
      padding: 0.25rem;
      width: 100%;
      height: 2.5rem;
    }
  }
  .noMobileFlex {
    @media screen and (max-width: 750px) {
      display: unset;
    }
  }
  .verticalLine {
    @media screen and (max-width: 750px) {
      display: none;
    }
  }
  .title {
    color: #000;
    margin-bottom: 10px;
    font-size: 35px;
    font-weight: 600;
  }
  .heading {
    color: black;
    margin-bottom: 0;
  }

  .mb {
    margin-bottom: 20px;
  }
  .mt {
    margin-top: 20px;
  }
  * {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;
  }
  .img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
  .btn-follow {
    padding: 0.25rem;
    height: 2.5rem;
    width: 10rem;
  }
  .margin-bottom {
    margin-bottom: 1rem;
  }
  .shadow {
    border-color: transparent;
    box-shadow: 0 1px 12px rgb(0 0 0 / 9%);
  }
  .company-card-options-mb {
    width: 100%;
    .ant-space-item {
      &:first-child {
        width: 100%;
      }
    }
  }
  .flex-bet-row {
    .company-card-desc {
      width: 70%;
      @media screen and (max-width: 920px) {
        width: 100%;
      }
    }
    .company-card-options {
      gap: 8px;
      width: 30%;
      @media screen and (max-width: 920px) {
        width: 100%;
      }
      justify-content: flex-end;
    }
  }
  .popover-design {
    margin-left: 10px;
    border: 1px solid rgb(63, 54, 54);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    :hover {
      background: rgba(59, 125, 221, 0.1);
      border: 1px solid #3b7ddd;
    }
  }
}
