.jobs {
  padding: 3% 4%;
  @media screen and (max-width: 1000px) {
    width: 100vw;
  }
  .ant-card-type-inner .ant-card-head {
    background: white;
  }
  .viewDetailWeb {
    @media screen and (max-width: 920px) {
      display: none;
    }
  }
  .btn-follow {
    padding: 0.25rem;
    width: 10rem;
    height: 2.5rem;
  }
  .viewDetailMobile {
    @media screen and (min-width: 920px) {
      display: none;
    }
    .btn-follow {
      padding: 0.25rem;
      width: 100%;
      height: 2.5rem;
    }
  }
  .noMobileFlex {
    @media screen and (max-width: 750px) {
      display: unset;
    }
  }
  .verticalLine {
    @media screen and (max-width: 750px) {
      display: none;
    }
  }
  .align-center-text {
    text-align: center;
  }
  .w-100 {
    width: 100%;
  }
  .shadow {
    border-color: transparent;
    box-shadow: 0 4px 12px rgb(0 0 0 / 9%);
  }
  .heading {
    color: #000;
    margin-bottom: 0%;
  }
  * {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
  }
  .margin-bottom {
    margin-bottom: 1rem;
  }
  .pl {
    padding-left: inherit;
  }

  .card-body {
    height: 7rem;
  }
}
