.signup {
  height: 100%;
  img.logo {
    width: 7em;
    height: auto;
    margin-left: 25px;
    margin-top: 25px;
    @media screen and (max-width: 550px) {
      margin-top: 0;
    }
  }
  &-image-text {
    height: 100%;
    width: 20%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: fixed;
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2)
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0) 24.01%, rgba(0, 0, 0, 0.39) 100%),
      url(~assets/signupImage.jpg);
    backdrop-filter: blur(4px);
    @media screen and (max-width: 768px) {
      width: 25%;
    }
    h1 {
      color: @white-color;
      font-weight: bold;
      padding: 40px 40px;
      display: flex;
      justify-content: flex-end;
      bottom: 4%;
      position: absolute;
      @media screen and (max-width: 768px) {
        padding: 20px;
      }
    }
  }
  &-detail-section {
    padding: 70px 75px;
    width: 100%;
    margin-left: 20%;
    @media screen and (max-width: 1024px) {
      padding: 50px;
    }
    @media screen and (max-width: 768px) {
      margin-left: 25%;
      padding: 30px;
    }

    @media screen and (max-width: 549px) {
      margin-left: 0;
      padding: 30px;
      background: @white-color;
    }
    input.ant-input {
      height: 35px;
    }
    h1 {
      font-weight: 600;
      font-size: xx-large;
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
      color: @black-color;
    }
  }
}

.signup-button-div {
  margin-top: 2%;
  button.ant-btn {
    height: 48px;
  }
}

span.ant-input-prefix {
  margin-right: 15px;
}

.ant-picker {
  width: 100%;
  height: 52px;
}

.signup-google-button {
  @media screen and (max-width: 550px) {
    width: 100%;
    svg.google-logo {
      margin-right: 50px;
    }
  }
}
