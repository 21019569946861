@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.common-drawer.ant-drawer-open {
  .ant-drawer-right {
    background: antiquewhite;
  }
  .ant-drawer-header {
    padding: 16px 10px;
  }
  .ant-drawer-body {
    padding: 0px;
  }
}
.common-drawer.ant-drawer-right {
  top: 72px;
}
.ant-drawer-right .ant-drawer-content-wrapper {
  width: 100% !important;
}

.common-drawer.ant-drawer-right.ant-drawer-open {
  width: 22% !important;
  @media screen and (min-width: 320px) and (max-width: 991px) {
    width: 100% !important;
  }
}

.header-section {
  .title {
    color: @black-color;
    font-size: 23px;
    font-weight: 600;
  }
  .back-btn {
    .anticon {
      margin-right: 12px;
      cursor: pointer;
    }
    span {
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.chat-content-wrapper {
  margin-top: 20px;
}
