.applied {
  background: #fff;
  // height: 100%;
  .applied-card {
    cursor: pointer;
  }
  p {
    font-size: 16px;
  }
  @media screen and (max-width: 1000px) {
    width: 100vw;
  }
  .viewDetailWeb {
    @media screen and (max-width: 750px) {
      display: none;
    }
  }
  * {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
  }
  .applied-btn {
    background: #ebf2fc;
    border-radius: 8px;
    color: #3b7ddd;
    border: #ebf2fc;
    width: 150px;
    &:hover {
      background: #ebf2fc;
      color: #3b7ddd;
    }
  }

  .view-detail-btn {
    background: #3b7ddd;
    font-size: 16px;
    font-weight: 700;
    font: 'Manrope', sans-serif;
    line-height: 16px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
  }

  .viewDetailMobile {
    @media screen and (min-width: 749px) {
      display: none;
    }
    .btn-follow {
      padding: 0.25rem;
      width: 100%;
      height: 2.5rem;
    }
  }
  .noMobileFlex {
    @media screen and (max-width: 500px) {
      display: unset;
    }
  }
  .verticalLine {
    @media screen and (max-width: 500px) {
      display: none;
    }
  }
  .align-center {
    text-align: center;
  }
  .w-100 {
    width: 100%;
  }
  button.ant-btn.ant-btn-round.highlight {
    background: #fff;
    &:hover,
    &:active,
    &:focus {
      border: #000 0.2px solid;
      background-color: #000;
      color: #fff;
    }
  }
  .heading {
    color: #000;
    margin-bottom: 10px;
    font-size: 35px;
    font-weight: 600;
  }

  .margin-bottom {
    margin-bottom: 1rem;
  }
  div#rc-tabs-0-tab-null {
    pointer-events: none;
  }

  .ant-tabs-tab {
    justify-content: center;
    font-size: 16px;
    width: 150px;
    &:hover,
    &:focus,
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-remove:active {
      color: #000;
    }
  }
  .ant-tabs-ink-bar {
    background: none;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
    text-shadow: 0 0 0.25px currentColor;
    background: #000;
    border-radius: 100px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    border: none;
    border-bottom: none;
  }
  .ant-card-type-inner .ant-card-head {
    background: white;
  }
  .ant-card.ant-card-bordered.ant-card-type-inner.applied-card {
    box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    border: none;
    padding: 20px;
    position: relative;
  }

  .applied-divider {
    height: auto;
  }
  .ant-divider.ant-divider-vertical.applied-divider {
    border-left-color: black;
  }
  .qualification-listing {
    padding-inline-start: 25px;
  }
  .application-status-div {
    background: #ebf2fc;
    border-radius: 12px 12px 0px 0px;
    position: absolute;
    right: 24px;
    height: 48px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 260px;
    span {
      color: #3b7ddd;
    }
  }
}

.padding-width {
  // width: 80vw;
  padding: 3% 4%;
}

.profile {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}
