@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.feed-module {
  ::-webkit-scrollbar {
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: #7f7f7f;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #7f7f7f;
  }
  .wrapper-class {
    display: flex;
    overflow-y: scroll;
    height: 100vh;
    @media screen and (min-width: 320px) and (max-width: 991px) {
      display: unset;
    }

    .feed-sidebar {
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      background: transparent;
      width: 15%;
      text-align: right;
      a {
        color: @black-color;
        font-size: 16px;
        font-weight: 400;
      }
      .menu {
        margin-top: 10%;
      }
      @media screen and (min-width: 320px) and (max-width: 991px) {
        display: none;
      }
    }
    .upload-post-section-upper {
      width: 38%;
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 30px;
      @media screen and (min-width: 320px) and (max-width: 991px) {
        width: 100%;
      }
    }
    .upload-post-section {
      width: 100%;
      height: 10em;
      padding: 25px;
      background: @white-color;
      box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      input.ant-input {
        height: 16px;
      }

      .input-area {
        padding: 17px 0px 20px 17px;
        height: 50px;
        border-radius: 100px;
        .input.ant-input {
          font-size: 16px;
          height: 16px;
        }
      }
      .upload-section {
        margin-top: 20px;
        margin-left: 62px;
        .upload-button {
          font-size: 14px;
          font-weight: 400;
          margin-left: 5px;
        }
      }
    }

    .filter-section {
      margin-top: 15px;
      height: 60px;
      background: @white-color;
      box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      padding: 12px 35px;
      display: flex;
      align-items: center;
      .ant-radio-button-wrapper {
        svg {
          font-size: 17px;
        }
      }
      .ant-radio-button-wrapper-checked {
        svg {
          fill: white !important;
        }
      }
      @media screen and (min-width: 320px) and (max-width: 991px) {
        padding: 17px 4px;
      }
      .feed-toggle {
        @media screen and (max-width: 420px) {
          font-size: 14px;
        }
      }
      .label-text {
        font-size: 16px;
        font-weight: 400;
        @media screen and (max-width: 769px) {
          font-size: 12px;
        }
      }
      .filter-divider {
        margin-left: 15px;
        background-color: #dde2e5;
        height: 16px;
      }
      label.ant-radio-button-wrapper {
        svg {
          fill: #000000ad;
        }
      }
      .ant-radio-group-large .ant-radio-button-wrapper {
        font-size: 16px;
      }

      .radio-button {
        display: flex;
        align-items: center;
        .ant-radio-button-wrapper:first-child {
          border-radius: 100px;
          height: 34px;
        }
        .ant-radio-button-wrapper-checked {
          border-radius: 10px;
        }
        .ant-radio-button-wrapper {
          border: none;
          color: #495057;
          background-color: transparent;
          line-height: 34px;
        }
        .ant-radio-button-wrapper-checked {
          color: @white-color;
          background-color: @black-color;
        }
        .ant-radio-button-wrapper:last-child {
          margin-left: 12px;
          border-radius: 100px;
          height: 34px;
        }
        .ant-radio-button-wrapper:not(:first-child)::before {
          content: none;
        }
      }
    }
    .post-section {
      margin-top: 15px;
      padding-bottom: 20px;
      .single-post {
        margin-top: 20px;
      }
    }
    .post-wrapper {
      background: @white-color;
      border-radius: 8px;
      padding: 15px 20px;
      .post-header {
        .text-section {
          margin-left: 10px;
          display: flex;
          flex-direction: column;

          .title {
            color: @black-color;
            font-size: 14px;
            font-weight: 600;
          }
          .sub-title {
            color: #495057;
            font-size: 13px;
          }
        }

        .connect-button {
          margin-right: 15px;
          border-radius: 8px;
          height: 36px;
          width: 100px;
        }
        .connect-button.ant-btn {
          padding: unset;
        }
      }

      .image-text {
        margin-top: 20px;
        color: @black-color;
        font-size: 14px;
        font-weight: 500;
        word-break: break-word;
        padding: 5px;
        cursor: pointer;
      }

      .only-text {
        color: @black-color;
        font-size: 20px;
        font-weight: 500;
      }

      .post-image {
        text-align: center;
        img {
          margin-top: 20px;
          height: auto;
          width: 100%;
          border-radius: 8px;
        }
      }

      .post-video {
        text-align: center;
        .video {
          margin-top: 10px;
          width: 100%;
          //height: 50vh;
          // border-radius: 8px;
        }
      }

      .like-section {
        margin-top: 24px;
        // margin-left: 10px;
        width: 100%;
        .image {
          cursor: pointer;
          height: 20px;
          width: 20px;
        }

        .ml-20 {
          margin-left: 20px;
        }
        .info-section {
          color: #495057;
          font-weight: 500;
          margin-left: 7px;
        }
      }

      .post-divider {
        margin: 15px 0 19px 0;
        background-color: #eeeeee;
      }

      .bio-section {
        color: #495057;
        font-size: 14px;
        //max-height: 100px;
        // overflow-y: hidden;
        // text-overflow: ellipsis;
      }
      .mt-20 {
        margin-top: 20px;
      }
    }
    .recommend-wrapper {
      width: 25%;
      padding-top: 30px;
      padding-right: 30px;
      .recommend-section {
        padding: 20px;
        background-color: @white-color;
        border-radius: 12px;
        margin-bottom: 30px;
        @media screen and (min-width: 320px) and (max-width: 991px) {
          display: none;
        }
        .header-section {
          padding: 25px 0px 0px 20px;
          font-size: 20px;
          font-weight: 600;
          color: @black-color;
        }

        .recommend-divider {
          background-color: #eeeeee;
          margin: 20px 0px 20px 0px;
        }

        .job-section {
          // max-height: calc(100vh - 324px);
          // overflow-y: scroll;
          .single-job {
            margin-top: 20px;
          }
        }

        .job-card {
          height: 165px;
          padding: 20px;
          background-color: @white-color;
          box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.04);
          border-radius: 12px;

          .job-title {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: @black-color;
            text-overflow: ellipsis;
            // overflow: hidden;
            overflow-y: hidden;
            overflow-x: hidden;
            white-space: nowrap;
            margin-bottom: 10px;
          }

          .link {
            color: #495057;
            font-size: 14px;
            text-decoration: underline;
            margin-left: 7px;
            text-overflow: ellipsis;
            width: 200px;
            height: 16px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;
          }

          .job-card-divider {
            background-color: #eeeeee;
            margin: 15px 0px 15px 0px;
          }

          .footer {
            .bold-text {
              font-size: 16px;
              font-weight: 400;
              color: @black-color;
            }
            .normal-text {
              font-size: 16px;
              color: @black-color;
            }
          }
        }
      }
    }
  }
}

.post-modal {
  .ant-modal-body {
    padding: 25px;
  }

  .post-description {
    margin-left: 10px;
  }
  .no-preview {
    padding: 10px 2%;
    background: #f9f9f9;
    border-radius: 7px;
    margin: 15px 0px;
  }
  .image-preview {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    //  background-color: antiquewhite;
    position: relative;

    .image {
      width: 100%;
    }
    .close-icon {
      cursor: pointer;
      position: absolute;
      right: 22px;
      top: 22px;
      z-index: 99;
    }
  }

  .video-preview {
    position: relative;
    width: 100%;
    padding-left: 60px;
    .video {
      width: 100%;
      height: 50vh;
    }
    .video-close-icon {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 99;
    }
  }
  .mt-20 {
    margin-top: 20px;
  }

  .upload-section {
    margin-left: 30px;
    cursor: pointer;
    .upload-button {
      margin-left: 4px;
    }
  }
}

.comment-modal {
  .ant-modal-body {
    padding: 25px;
  }

  .main-title {
    font-size: 20px;
    font-weight: 600;
    color: @black-color;
  }

  .mt-25 {
    margin-top: 25px;
  }

  .comment-user-info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    .title {
      color: @black-color;
      font-size: 14px;
      font-weight: 600;
    }
    .sub-title {
      color: #495057;
      font-size: 13px;
    }
  }

  .comment-input-section {
    margin-top: 20px;
  }
}

.comment-pop-over {
  .ant-popover-inner {
    background-color: violet;
  }
}

.post-detail-wrapper {
  height: 100vh;
  // position: absolute;
  @media screen and (max-width: 769px) {
    display: flex;
    flex-direction: column;
  }
  .image-section {
    width: 75%;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
    .post-image {
      text-align: center;
      position: relative;
      height: 100%;
      background-color: black;
      @media screen and (max-width: 769px) {
        background-color: black;
      }
      .post-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0px 15px;
        @media screen and (max-width: 769px) {
          padding: 0px;
        }
      }
      img {
        max-height: 100%;
        max-width: 100%;
      }
      .close-icon {
        cursor: pointer;
        height: 30px;
        width: 30px;
        position: absolute;
        right: 22px;
        top: 22px;
        z-index: 99;
      }
    }

    .post-video {
      text-align: center;
      position: relative;
      .video {
        height: 100vh;
        width: 100%;
        @media screen and (max-width: 769px) {
          height: 50vh;
        }
      }
      .close-icon {
        cursor: pointer;
        height: 30px;
        width: 30px;
        position: absolute;
        right: 22px;
        top: 22px;
        z-index: 99;
      }
    }
  }
  .comment-section {
    width: 25%;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
    ::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      border-radius: 25px;
      background: #7f7f7f;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #7f7f7f;
    }
    .text-section {
      margin-left: 10px;
      display: flex;
      flex-direction: column;

      .title {
        color: @black-color;
        font-size: 14px;
        font-weight: 600;
      }
      .sub-title {
        color: #495057;
        font-size: 13px;
      }
    }
    .connect-button {
      margin-right: 10px;
      border-radius: 8px;
      height: 36px;
      width: 100px;
    }
    .connect-button.ant-btn {
      padding: unset;
    }
    .bio-section {
      margin-top: 25px;
      color: #495057;
      font-size: 14px;
      max-height: 300px;
      overflow-y: auto;
      // max-height: 100px;
      // overflow-y: hidden;
      // text-overflow: ellipsis;
    }
    .post-divider {
      margin: 15px 0 19px 0;
      background-color: #eeeeee;
    }

    .like-section {
      margin-top: 24px;
      margin-left: 10px;

      .image {
        height: 20px;
        width: 20px;
        cursor: pointer;
      }

      .info-section {
        color: #495057;
        font-weight: 500;
        margin-left: 11px;
      }
    }
    .comment-input {
      height: 50px;
      border-radius: 100px;
      background-color: #eeeeee;
      .ant-input {
        background-color: #eeeeee;
      }
    }
    .comment-input.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      background-color: #eeeeee;
    }

    .header {
      padding: 5% 0 0 5%;
      span {
        font-size: 16px;
        color: @black-color;
        font-weight: 400;
      }
    }
    .comment-list {
      padding: 0px 12px;
      // max-height: calc(100vh - 434px);
      overflow-y: scroll;

      .single-comment {
        margin-top: 30px;
        .comment-header {
          display: flex;
          justify-content: space-between;
          .author-name {
            margin-left: 10px;
          }
        }
        .comment-content {
          margin-top: 8px;
          background: #eeeeee;
          border-radius: 8px;
          width: 100%;
          padding: 12px 22px;
        }
      }
    }
  }
}

.search-button {
  height: 30px;
  margin-top: 5px;
  margin-left: 5px;
}

// button.ant-btn.ant-btn-primary.ant-btn-round {
// }

button.ant-btn.ant-btn-primary.ant-btn-round.post-button {
  padding: 10px 18px;
  width: 72px;
  height: 36px;
  display: flex;
  align-items: center;
}
