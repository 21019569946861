@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.chat-wrapper {
  height: 100%;

  //* chat.less
  &-chat {
    background-color: @white-color;
    height: 100%;
    @media screen and (max-width: 1000px) {
      height: 100%;
      width: 100vw;
    }
    .header-wrapper {
      row-gap: 0px;
      height: 70px;
      top: 0;
      padding: 10px 15px;
      position: fixed;
      background-color: white;
      width: 100%;
      z-index: 1;
      .header {
        display: flex;
        h1 {
          font-weight: 600;
          align-items: center;
          justify-content: center;
          font-size: 23px;
          margin-bottom: 10px;
        }
      }
    }

    .new-msg.ant-btn-primary {
      background: rgba(59, 125, 221, 0.1);
      border-color: transparent;
      border-radius: 25px;
      color: black;
      font-size: 16px;
      font-weight: 500;
      align-items: center;
      padding: 0px 10px;
      display: flex;
      align-items: center;
      span {
        margin-left: 5px;
      }
    }
    .chat-main-body {
      height: calc(100vh - 70px);
      overflow-y: scroll;
      top: 70px;
      position: fixed;
      width: 100%;
      padding: 0 15px;
      .chat-msg {
        .time.unread {
          color: #3b7ddd;
        }
        .title {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
        }
        .max-60 {
          max-width: 60%;
        }
        .min-60 {
          min-width: 60%;
        }
        .min-80 {
          min-width: 80%;
        }
        .max-80 {
          max-width: 80%;
        }

        .msg {
          font-size: 14px;
          font-weight: 400;
          color: #495057;
          margin-top: 5px;
          width: 60%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .msg-count {
          background: #3b7ddd;
          font-size: 10px !important;
          margin-top: 5px;
        }
      }
    }

    .header-divider {
      margin: 20px 0px;
    }
    .chat-divider {
      margin: 12px 0px;
    }
    .user-profile {
      margin-right: 2%;
    }
    .ant-select.ant-select-single.ant-select-show-arrow {
      height: 40px;
      .ant-select-selector {
        height: 100%;
      }
    }
    .margin-10 {
      margin: 10px;
      @media screen and (max-width: 550px) {
        margin: 0% 50%;
      }
    }
    .salary.ant-form-item {
      @media screen and (max-width: 550px) {
        margin-bottom: 0px;
      }
    }
    .salary-label {
      margin-bottom: 8px;
    }
    span.ant-tag.edit-tag {
      height: 44px;
      margin-top: 10px;
      background: #eeeeee;
      border-radius: 100px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      .ant-tag-close-icon {
        font-size: 18px;
      }
      @media screen and (max-width: 550px) {
        margin-top: 5px;
        display: flex;
      }
    }
  }

  //* new-chat.less
  &-new-chat {
    background-color: @white-color;
    height: 100%;
    @media screen and (max-width: 1000px) {
      height: 100%;
      width: 100vw;
    }
    .header-wrapper {
      row-gap: 0px;
      height: 70px;
      top: 0;
      padding: 10px;
      position: fixed;
      background-color: white;
      width: 100%;
      z-index: 1;
      .header {
        display: flex;
        h1 {
          font-weight: 600;
          align-items: center;
          justify-content: center;
          font-size: 23px;
          margin-bottom: 10px;
        }
      }
    }
    .user-search-wrapper {
      position: fixed;
      top: 70px;
      background: #ffffff;
      z-index: 1;
      height: 50px;
      width: 100%;
      .user-search {
        border: none;
        padding: 6px 10px;
        height: 100%;
        .ant-input {
          background: #f9f9f9;
          padding: 0px 5px;
        }
      }
    }
    .chat-main-body {
      height: calc(100vh - 120px);
      overflow-y: scroll;
      top: 120px;
      position: fixed;
      width: 100%;
      padding: 0 15px;
    }

    .new-msg.ant-btn {
      border-color: transparent;
      background: transparent;
      color: #495057;
      font-size: 16px;
      font-weight: 500;
      box-shadow: none;
      span {
        margin-left: 5px;
      }
    }
    .chat-msg {
      p {
        color: #3b7ddd;
      }
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        padding: 10px 5px;
      }
      .msg {
        font-size: 14px;
        font-weight: 400;
        color: #495057;
        margin-top: 5px;
      }
    }
    .header-divider {
      margin: 20px 0px;
    }
    .chat-divider {
      margin: 12px 0px;
    }
    .user-profile {
      margin-right: 2%;
    }
    .ant-select.ant-select-single.ant-select-show-arrow {
      height: 40px;
      .ant-select-selector {
        height: 100%;
      }
    }
    .margin-10 {
      margin: 10px;
      @media screen and (max-width: 550px) {
        margin: 0% 50%;
      }
    }
    .salary.ant-form-item {
      @media screen and (max-width: 550px) {
        margin-bottom: 0px;
      }
    }
    .salary-label {
      margin-bottom: 8px;
    }
    span.ant-tag.edit-tag {
      height: 44px;
      margin-top: 10px;
      background: #eeeeee;
      border-radius: 100px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      .ant-tag-close-icon {
        font-size: 18px;
      }
      @media screen and (max-width: 550px) {
        margin-top: 5px;
        display: flex;
      }
    }
  }

  //*user-chat.less

  &-user-chat {
    background-color: @white-color;
    height: 100%;
    @media screen and (max-width: 1000px) {
      height: 100%;
      width: 100vw;
    }
    .header-wrapper {
      row-gap: 0px;
      height: 70px;
      top: 0;
      padding: 10px 0px;
      position: fixed;
      background-color: white;
      width: 100%;
      z-index: 1;
      .header {
        .title {
          font-size: 19px;
          font-weight: 600;
          color: #000000;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .chat-messages {
      height: calc(100vh - 75px);
    }
    .another-user {
      margin-top: 30px;
    }
    .owner-user {
      margin-top: 30px;
    }
    .owner-msg {
      background: #f5f6f8;
      border-radius: 20px;
      color: black;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      padding: 12px 22px;
      padding-left: 10px !important;
      padding-right: 10px !important;
      min-width: 30px;
      max-width: 70%;
    }
    .owner-msg-image {
      padding: 0px !important;
    }
    .msg-image {
      height: 150px;
      width: 200px;
      background-size: cover;
      border-radius: 15px;
    }
    .another-user-msg {
      background: #3b7ddd;
      border-radius: 20px;
      color: white;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      padding: 12px 22px;
      width: auto;
      max-width: 70%;
      padding-left: 10px !important;
      padding-right: 10px !important;
      min-width: 30px;
      max-width: 70%;
    }
    .message-content {
      position: fixed;
      width: 100%;
      top: 70px;
      bottom: 50px;
      overflow-y: scroll;
      padding: 0px 12px;
    }
    .chat-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: @white-color;
      .user-message {
        border: none;
      }
      .ant-row-middle {
        height: 50px;
        .side-col {
          width: 50px;
        }
        .message-col {
          height: 100%;
          width: calc(100% - 100px);
        }
      }
    }
    .new-msg.ant-btn {
      border-color: transparent;
      background: transparent;
      color: #495057;
      font-size: 16px;
      font-weight: 500;
      box-shadow: none;
      span {
        margin-left: 5px;
      }
    }
    .chat-msg {
      p {
        color: #3b7ddd;
      }
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        padding: 10px 5px;
      }
      .msg {
        font-size: 14px;
        font-weight: 400;
        color: #495057;
        margin-top: 5px;
      }
    }
    .header-divider {
      margin: 20px 0px;
    }
    .chat-divider {
      margin: 12px 0px;
    }
    .user-profile {
      margin-right: 2%;
    }
    .ant-select.ant-select-single.ant-select-show-arrow {
      height: 40px;
      .ant-select-selector {
        height: 100%;
      }
    }
    .margin-10 {
      margin: 10px;
      @media screen and (max-width: 550px) {
        margin: 0% 50%;
      }
    }
    .salary.ant-form-item {
      @media screen and (max-width: 550px) {
        margin-bottom: 0px;
      }
    }
    .salary-label {
      margin-bottom: 8px;
    }
    .message-content {
      display: flex;
      flex-direction: column-reverse;
    }
    span.ant-tag.edit-tag {
      height: 44px;
      margin-top: 10px;
      background: #eeeeee;
      border-radius: 100px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      .ant-tag-close-icon {
        font-size: 18px;
      }
      @media screen and (max-width: 550px) {
        margin-top: 5px;
        display: flex;
      }
    }
  }
}
.loader-attachment {
  background: #00000061;
  z-index: 200000000;
  position: absolute;
  width: 100%;
}
