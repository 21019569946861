@import '../../styles/variables.less';

.ant-modal {
  &.modal {
    .ant-modal-title {
      //color: @field-color;
      font-weight: 600;
    }
    .ant-modal-close-x {
      .anticon-close {
        color: @black-color;
      }
    }
    .ant-modal-header {
      padding: 16px;
      border-radius: 12px 12px 0 0;
    }
    .ant-modal-body {
      max-height: 500px;
      overflow-y: auto;
      padding: 16px;
    }
  }
}
